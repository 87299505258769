import { Box, Button, makeStyles, SwipeableDrawer, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '20px 20px 0px 0px',
  },
  title: {
    fontWeight: 700,
    paddingBottom: 20,
  },
  reqSrvBtn: {
    borderRadius: 12,
    marginBottom: 10,
    '& .MuiButton-label': {
      padding: '8px 4px',
      justifyContent: 'space-between',
    },
  },
}));

function ReqSrvBtnDrawer({ open, openDrawer, closeDrawer }) {
  const classes = useStyles();
  const history = useHistory();

  const goAddserviceView = async (srType) => {
    closeDrawer();
    history.push(`/mchown/service/add/${srType}`);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={closeDrawer}
      onOpen={openDrawer}
      classes={{ paper: classes.paper }}
    >
      <Box p={2} role="presentation" onKeyDown={closeDrawer}>
        <Typography className={classes.title} variant="h5" align="center">
          수리형태 선택
        </Typography>
        <Button
          fullWidth
          variant="contained"
          className={classes.reqSrvBtn}
          endIcon={<img src="/assets/images/main/rush.png" alt="rush" />}
          onClick={() => goAddserviceView('MO006001')}
        >
          <div>
            <Typography variant="h4" align="left" style={{ fontWeight: 700 }}>
              응급수리
            </Typography>
            <Typography variant="h5" align="left">
              당장 일해야 하는데, 장비가 고장 났어요!
            </Typography>
          </div>
        </Button>
        <Button
          fullWidth
          variant="contained"
          className={classes.reqSrvBtn}
          endIcon={<img src="/assets/images/main/calendar.png" alt="calendar" />}
          onClick={() => goAddserviceView('MO006002')}
        >
          <div>
            <Typography variant="h4" align="left" style={{ fontWeight: 700 }}>
              예약수리
            </Typography>
            <Typography variant="h5" align="left">
              급하진 않지만, 가능한 빨리 고쳐 주세요.
            </Typography>
          </div>
        </Button>
      </Box>
    </SwipeableDrawer>
  );
}

ReqSrvBtnDrawer.propTypes = {
  open: PropTypes.bool,
  openDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
};

ReqSrvBtnDrawer.defaultProps = {
  open: false,
  openDrawer: () => {},
  closeDrawer: () => {},
};
export default ReqSrvBtnDrawer;
