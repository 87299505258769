import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ko';
import codeApi from 'src/api/CodeApi';
import moServiceApi from 'src/api/MoServiceApi';
import atchGrpService from 'src/services/AtchGrpService';
import XpnrUtils from 'src/utils/XpnrUtils';

// 장비군별 고장부위 가져오기
export const getFailureParts = createAsyncThunk(
  'mchown/getFailureParts',
  async ({ machineId, failurePart }, { rejectWithValue }) => {
    try {
      const response = await codeApi.getListProductFailurePart(machineId);
      const list = response.map((item) => item.failurePartCode);

      let _failurePart = failurePart;
      if (!list.includes(failurePart)) _failurePart = '';

      return { list, failurePart: _failurePart };
    } catch (error) {
      return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
    }
  }
);

// 장비군별 고장형태 가져오기
export const getFailureTypes = createAsyncThunk(
  'mchown/getFailureTypes',
  async ({ machineId, failureType }, { rejectWithValue }) => {
    try {
      const response = await codeApi.getListProductFailureType(machineId);
      const list = response.map((item) => item.failurePartCode);

      let _failureType = failureType;
      if (!list.includes(failureType)) _failureType = '';

      return { list, failureType: _failureType };
    } catch (error) {
      return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
    }
  }
);

// imageList 업로드 서버요청
const uploadImageList = (uploadForm) => {
  return new Promise((resolve, reject) => {
    //
    if (_.isEmpty(uploadForm)) {
      resolve('');
      return;
    }
    // 업로드 할 수 있는지 체크
    if (!atchGrpService.canBeUpload(uploadForm)) {
      const { atchGrpNo } = uploadForm;
      resolve(atchGrpNo);
      return;
    }
    // 첨부리스트 서버에호출
    atchGrpService.upload(uploadForm).then((data) => {
      const { action, atchGrpNo } = data;
      // 성공한 첨부그룹번호 리턴
      if (action === 'delete') {
        resolve('');
      } else {
        resolve(atchGrpNo);
      }
    });
  });
};

// 서비스 요청 추가
export const addSrvcReq = createAsyncThunk('mchown/addSrvcReq', async (arg, { getState, rejectWithValue }) => {
  const { srvcReq } = getState().mchown.srvcReq;

  const { uploadForm } = srvcReq;

  // 서비스신청 등록 순서 1.업로드 처리 -> 2.서비스신청 정보 등록
  // 1.업로드 처리
  const atchGrpNo = await uploadImageList(uploadForm);

  // 2.서비스신청
  const _srvcReq = { ...srvcReq, attachGroupId: atchGrpNo };
  try {
    const result = await moServiceApi.save(_srvcReq);

    return result;
  } catch (error) {
    return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

//   const request = axios.get(`/api/mchown/srvc/srvcreq/${srId}`);
export const getSrvcReq = createAsyncThunk('mchown/getSrvcReq', async (srId, { rejectWithValue }) => {
  try {
    const response = await moServiceApi.getSrvcReq(srId);

    const srDts = response.srDts.map((__) =>
      // 포맷 변경
      moment(__.srDt, 'YYYY-MM-DD[T]HH:mm:ss').format('YYYY-MM-DD HH:mm')
    );

    const srTime = moment(response.srTime, 'hh:mm:ss');

    return { ...response, srDts, srTime };
  } catch (error) {
    return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

// 서비스요청취소
export const deleteSrvcReq = createAsyncThunk('mchown/deleteSrvcReq', async (arg, { rejectWithValue, getState }) => {
  const { srvcReq } = getState().mchown.srvcReq;
  try {
    const response = await moServiceApi.delete(srvcReq.srId);

    return response;
  } catch (error) {
    return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

export const defaultSrvcReqState = {
  srId: null,
  machineId: '',
  failurePart: '',
  failureType: '',
  failureContent: '',
  attachGroupId: '',
  srType: 'MO006002', // 예약수리
  srDateStart: new Date(),
  srDateEnd: new Date(),
  srTime: new Date(),
  address: '',
  latitude: 0,
  longitude: 0,
  warrantyType: 'MO007002', // 일반수리
  uploadForm: null,
  srDts: [],
  srStatus: 'MO003001', // '등록' or '접수대기중' 상태
};

const srvcReqSlice = createSlice({
  name: 'mchown/srvcReq',
  // initialState: initialSrvcReqsState,
  initialState: {
    srvcReq: defaultSrvcReqState,
    selectedDate: new Date(),
    selectedItems: [],
    srDts: [],
    failureParts: [],
    failureTypes: [],
  },
  reducers: {
    setSrvcReq: (state, action) => {
      state.srvcReq = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    setSrDts: (state, action) => {
      state.srDts = action.payload;
    },
    setDefaultSrvcReq: (state, action) => ({
      srvcReq: {
        ...defaultSrvcReqState,
        srType: state.srvcReq.srType, // 응급/예약수리여부만 제외하고
      },
      selectedDate: new Date(),
      selectedItems: [],
      srDts: [],
      failureParts: [],
      failureTypes: [],
    }),
  },
  extraReducers: {
    [getFailureParts.fulfilled]: (state, action) => ({
      ...state,
      srvcReq: {
        ...state.srvcReq,
        failurePart: action.payload.failurePart,
      },
      failureParts: action.payload.list,
    }),
    [getFailureTypes.fulfilled]: (state, action) => ({
      ...state,
      srvcReq: {
        ...state.srvcReq,
        failureType: action.payload.failureType,
      },
      failureTypes: action.payload.list,
    }),
    [addSrvcReq.fulfilled]: (state, action) => {
      state.srvcReq = action.payload;
    },
    [getSrvcReq.fulfilled]: (state, action) => {
      state.srvcReq = action.payload;
    },
  },
});

export const { setSrvcReq, setSelectedDate, setSelectedItems, setSrDts, setDefaultSrvcReq } = srvcReqSlice.actions;
export default srvcReqSlice.reducer;
