import Axios from 'axios';

class AuthApi {
  async tokens(authorizeCode) {
    const formData = new FormData();
    formData.append('authorizeCode', authorizeCode);
    return new Promise((resolve, reject) => {
      Axios.post('/api/auth/tokens', formData, { useInterceptors: false })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error('[Auth Api]: ', err);
          reject(new Error('토큰 정보를 불러오는데 실패했습니다.'));
        });
    });
  }

  async refresh(refreshToken) {
    const formData = new FormData();
    formData.append('refreshToken', refreshToken);
    return new Promise((resolve, reject) => {
      Axios.post('/api/auth/refresh', formData, { headers: { Authorization: '' }, useInterceptors: false })
        // Authorization이 있으면 refresh 요청 자체가 401오류를 뱉어냄
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error('[Auth Api]: ', err.response.data.msg);
          reject(new Error('refresh 토큰 정보를 불러오는데 실패했습니다.'));
        });
    });
  }

  // me는 자기 백엔드에 호출 (next.config.js 참조)
  async me() {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/auth/me`, null, { useInterceptors: false })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error('[Auth Api]: ', err);
          reject(new Error('유저 정보를 불러오는데 실패했습니다.'));
        });
    });
  }
}

export const authApi = new AuthApi();
