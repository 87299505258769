import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moServiceApi from 'src/api/MoServiceApi';
import XpnrUtils from 'src/utils/XpnrUtils';

export const initParams = {
  size: 10,
  page: 1,
  srStatus: '',
  machineId: '',
  recentMonth: 0,
  sort: 'srId,desc',
};

export const getListRecentSrvc = createAsyncThunk(
  'mchown/getListRecentSrvc',
  async (param = initParams, { rejectWithValue }) => {
    const { srStatus, machineId, recentMonth, page } = param;

    const params = {
      ...initParams,
      page: page - 1,
      srStatus,
      machineId,
      recentMonth,
    };
    try {
      const response = await moServiceApi.getRecentSrvc(params);
      return response;
    } catch (error) {
      return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
    }
  }
);

const initialState = {
  content: [],
  number: 1,
  totalElements: 0,
  totalPages: 0,
};

const recentSrvcSlice = createSlice({
  name: 'mchown/recentSrvc',
  initialState,
  reducers: {},
  extraReducers: {
    [getListRecentSrvc.fulfilled]: (state, action) => {
      const { content, number, totalElements, totalPages } = action.payload;

      return {
        ...state,
        content,
        number: number + 1,
        totalElements,
        totalPages,
      };
    },
  },
});

export default recentSrvcSlice.reducer;
