import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router';
import { authApi } from 'src/api/AuthApi';
import { AUTH_ROLES } from 'src/config';
import { useInApp } from 'src/hooks/useInapp';
import * as cognito from 'src/services/cognito-service';
import { removeAuthorizationHeader, setAuthorizationHeader, setResponseInterceptor } from 'src/utils/axios';

const initUserData = {
  id: '',
  usrId: '',
  usrNm: '방문자',
  nickNm: 'guest',
  phone: '00000000000',
  email: 'guest@xpanner.com',
  userType: '',
};

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: initUserData,
};

const AuthContext = createContext({
  ...initialState,
  logout: () => Promise.resolve(),
  globalLogout: () => Promise.resolve(),
  refreshTokens: () => Promise.resolve(),
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case 'LOADING': {
      return {
        ...state,
        isInitialized: false,
      };
    }
    case 'SIGN_OUT': {
      removeAuthorizationHeader();
      return {
        ...state,
        isAuthenticated: false,
        isInitialized: true,
        user: initialState.user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { signOutofAxiosInterceptor } = useInApp();

  const initialize = useCallback(async () => {
    dispatch({ type: 'LOADING' });

    try {
      const currentTokens = await cognito.getCurrentTokens();
      if (currentTokens) {
        setAuthorizationHeader('Bearer', currentTokens.accessToken);
        const user = await authApi.me();
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: initialState.user,
          },
        });
      }
    } catch (e) {
      await cognito.signOut();
      dispatch({ type: 'SIGN_OUT' });
    }
  }, [dispatch]);

  const check = useCallback(async () => {
    const { isInitialized, user } = state;
    if (!isInitialized) return;
    const isAuth = await cognito.checkTokens();

    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: isAuth,
        user,
      },
    });
  }, [state]);

  const refreshTokens = useCallback(async () => {
    try {
      const token = await cognito.refreshTokens();
      setAuthorizationHeader('Bearer', token.accessToken || '');
      initialize();
    } catch (e) {
      await cognito.signOut();
      dispatch({ type: 'SIGN_OUT' });
    }
  }, [dispatch]);

  // TODO 로그아웃 후 account 로 가지 않고 시작화면(landing)으로 가게 하려면...
  const logout = useCallback(async () => {
    await cognito.signOut();
    await dispatch({ type: 'SIGN_OUT' });
  }, [dispatch]);

  const hasRoleOwner = () => {
    const { roles } = state.user;

    if (roles) {
      const result = roles.some((i) => [AUTH_ROLES.mchown].includes(i));
      return result;
    }
    return false;
  };

  useEffect(() => {
    setResponseInterceptor((path) => history.push(path), signOutofAxiosInterceptor);
    initialize();
  }, []);

  useEffect(() => {
    check();
  }, [dispatch, location.pathname]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
        refreshTokens,
        hasRoleOwner,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
