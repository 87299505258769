import Axios from 'axios';
import { refreshTokens, setCurrentSession } from 'src/services/cognito-service';
import SnackBarUtils from './SnackBarUtils';

export const setAuthorizationHeader = (tokenType, accessToken) => {
  Axios.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
};

export const removeAuthorizationHeader = () => {
  delete Axios.defaults.headers.common.Authorization;
};

export const setResponseInterceptor = (errCallback, signOutofAxiosInterceptor) => {
  Axios.interceptors.response.use(
    (response) => response,
    (err) =>
      new Promise(() => {
        const { status, data, config } = err.response;
        const { code } = data;
        // error 401 (accessToken 만료))
        if (status === 401) {
          if (code === 'C007') {
            SnackBarUtils.error('유효하지 않은 토큰입니다. 토큰갱신처리됩니다.');
            refreshTokens()
              .then((token) => {
                setCurrentSession(token);
                window.location.reload();
              })
              .catch((err2) => {
                console.error(err2);
                signOutofAxiosInterceptor();
                throw err2;
              });
          }
        }

        if (config.useInterceptors === false) {
          throw err;
        }

        console.error(err);

        switch (status) {
          case 400:
            errCallback('/400');
            break;
          case 401:
            errCallback('/401');
            break;
          case 403:
            errCallback('/403');
            break;
          case 500:
          default:
            errCallback('/500');
            break;
        }
      })
  );
};
