import axios from 'axios';

class MchownMainApi {
  calendar = (thisMonth) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/mchown/main/calendar`, {
          params: {
            thisMonth,
          },
        })
        .then((response) => resolve(response.data.list))
        .catch((error) => {
          console.error('[FinderMchownMainApi]: ', error);
          reject(error.response);
        });
    });
  };
}

const mchownMainApi = new MchownMainApi();

export default mchownMainApi;
