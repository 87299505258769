import axios from 'axios';
import _ from 'lodash';

const useInterceptors = false;

class CodeApi {
  // GET /api/v2/cmm/code/get
  // paramCode : [{ code1: '001', code2: '001' }]
  getMultiCodeList = (paramCode) => {
    const url = _.map(paramCode, (p) => `firstTypeCode=${p.code1}&secondTypeCode=${p.code2}`).join('&');
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/code/get?${url}`, { useInterceptors })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[FinderCodeApi]: ', err.response);
          reject(new Error(msg || '공통 코드 조회에 실패하였습니다.'));
        });
    });
  };

  // GET /api/v2/cmm/code/machine/model
  getMchModelsAll = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/code/machine/model`, { params: 'finder', useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[FinderCodeApi]: ', err.response);
          reject(new Error(msg || '모델 리스트 조회에 실패하였습니다.'));
        });
    });

  // GET /api/v2/cmm/code/machine/getFailureParts
  getListProductFailurePart = (machineId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/code/machine/getFailureParts/${machineId}`, { useInterceptors })
        .then((response) => resolve(response.data.list))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[FinderCodeApi]: ', err.response);
          reject(new Error(msg || '고장 부위 조회에 실패하였습니다.'));
        });
    });

  // GET /api/v2/cmm/code/machine/getFailureParts
  getListProductFailureType = (machineId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/code/machine/getFailureTypes/${machineId}`, { useInterceptors })
        .then((response) => resolve(response.data.list))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[FinderCodeApi]: ', err.response);
          reject(new Error(msg || '고장 타입 조회에 실패하였습니다.'));
        });
    });
}

const codeApi = new CodeApi();

export default codeApi;
