// 참고: https://github.com/navermaps/maps.js.ncp/blob/master/examples/geocoder/3-geocoder-geocoding.html
/* eslint-disable */
class NaverMapUtils {
  // 좌표로 주소를 검색(Reversegeocode)한 결과에서 주소값 추출
  static makeAddress(item) {
    if (!item) {
      return;
    }

    const name = item.name;
    const region = item.region;
    const land = item.land;
    const isRoadAddress = name === 'roadaddr';

    let sido = '';
    let sigugun = '';
    let dongmyun = '';
    let ri = '';
    let rest = '';

    if (this.hasArea(region.area1)) {
      sido = region.area1.name;
    }

    if (this.hasArea(region.area2)) {
      sigugun = region.area2.name;
    }

    if (this.hasArea(region.area3)) {
      dongmyun = region.area3.name;
    }

    if (this.hasArea(region.area4)) {
      ri = region.area4.name;
    }

    if (land) {
      if (this.hasData(land.number1)) {
        if (this.hasData(land.type) && land.type === '2') {
          rest += '산';
        }
        rest += land.number1;

        if (this.hasData(land.number2)) {
          rest += '-' + land.number2;
        }
      }

      if (isRoadAddress === true) {
        if (this.checkLastString(dongmyun, '면')) {
          ri = land.name;
        } else {
          dongmyun = land.name;
          ri = '';
        }

        if (this.hasAddition(land.addition0)) {
          rest += ' ' + land.addition0.value;
        }
      }
    }

    return `${sido} ${sigugun} ${dongmyun} ${ri} ${rest}`;
  }

  static hasArea(area) {
    return !!(area && area.name && area.name !== '');
  }

  static hasData(data) {
    return !!(data && data !== '');
  }

  static checkLastString(word, lastString) {
    return new RegExp(lastString + '$').test(word);
  }

  static hasAddition(addition) {
    return !!(addition && addition.value);
  }
}
/* eslint-enable */

export default NaverMapUtils;
