// 1. React JS 컴포넌트 import
import koLocale from 'date-fns/locale/ko';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { useEffect } from 'react';
import { RenderAfterNavermapsLoaded } from 'react-naver-maps';

// 2. 디자인 컴포넌트 import
import { jssPreset, makeStyles, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// 3. Xpanner JS 기본 컴포넌트 import
import { SnackbarProvider } from 'notistack';
import GlobalStyles from './components/GlobalStyles';
import routes, { renderRoutes } from './routes';
import createTheme from './theme';

// 4. 업무 화면 컴포넌트 import
import KakaoPostcodeDialog from './components/KakaoPostcodeDialog';
import XpnrDialog from './components/XpnrDialog';
import XpnrSplashScreen from './components/XpnrSplashScreen';
import { NCP_CLIENT_ID, THEMES } from './config';
import AuthContext, { AuthProvider } from './contexts/AuthContext';
import InAppContext, { InAppProvider } from './contexts/InAppContext';
import { XpnrProvider } from './contexts/XpnrContext';
import { SnackbarUtilsConfigurator } from './utils/SnackBarUtils';
import XpnrDateFnsUtils from './utils/XpnrDateFnsUtils';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
// const history = createBrowserHistory();

const useStyles = makeStyles(() => ({
  snack: {
    '& .MuiCollapse-wrapperInner': {
      margin: 'auto',
    },
  },
}));

function App() {
  const theme = createTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.LIGHT,
  });
  const classes = useStyles();

  useEffect(() => {
    // prettier-ignore
    console.log(
        "   __ __ _____ _____ _____ _____ _____ _____ \n"+
        "  |  |  |  _  |  _  |   | |   | |   __| __  |\n"+
        "  |-   -|   __|     | | | | | | |   __|    -|\n"+
        "  |__|__|__|  |__|__|_|___|_|___|_____|__|__|\n"+
        "                            __\n"+
        "      __                   //\\\\`'-.___\n"+
        "     //\\\\  _______        //  \\\\  _(=()__\n"+
        "    //  \\\\//~//.--|       Y    \\\\//~//.--|\n"+
        "    Y   /\\\\~~//_  |       :    /\\\\~~//_  |\n"+
        "   _L  |_((_|___L_|      _L   |_((_|___L_|\n"+
        "  (/\\)(____(_______)    (/\\) (____(_______)  \n"+
        "\n"+
        "                              _ _\n"+
        "                        _ ___L_|_\\      ____\n"+
        "                       L_|_____|__]   ,' = =`---.\n"+
        "                        (_(_)  (_)    `(_)--(_)-'\n"+
        "                        \n"+
        "--------------------------------------------------\n"+
        "   🔧🔧🔧 Hello World XPANNER Finder MO 🔧🔧🔧 \n"+
        "--------------------------------------------------"
      )
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <RenderAfterNavermapsLoaded ncpClientId={NCP_CLIENT_ID} submodules={['geocoder']}>
          <MuiPickersUtilsProvider utils={XpnrDateFnsUtils} locale={koLocale}>
            <XpnrProvider>
              <SnackbarProvider
                dense
                maxSnack={3}
                autoHideDuration={2500}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{ containerRoot: classes.snack }}
              >
                <SnackbarUtilsConfigurator />
                <GlobalStyles />
                <XpnrDialog />
                <KakaoPostcodeDialog />
                <InAppProvider>
                  <InAppContext.Consumer>
                    {({ isInApp, isInAppInit }) =>
                      !isInApp || isInAppInit ? (
                        <AuthProvider>
                          <AuthContext.Consumer>
                            {({ isInitialized }) => (!isInitialized ? <XpnrSplashScreen /> : renderRoutes(routes))}
                          </AuthContext.Consumer>
                        </AuthProvider>
                      ) : (
                        <XpnrSplashScreen />
                      )
                    }
                  </InAppContext.Consumer>
                </InAppProvider>
              </SnackbarProvider>
            </XpnrProvider>
          </MuiPickersUtilsProvider>
        </RenderAfterNavermapsLoaded>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
