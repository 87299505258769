import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const XpnrConfirmDialog = ({ title, message, okClick, okBtnLlb, cancelClick, cancelBtnLlb, showCancelBtn }) => {
  return (
    <>
      {title !== '' && (
        <DialogTitle id="confirm-dialog-title" disableTypography>
          <Typography variant="h5" color="textPrimary">
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>
        {typeof message === 'string' ? <DialogContentText>{message}</DialogContentText> : message}
      </DialogContent>
      <DialogActions>
        {showCancelBtn && (
          <Button onClick={cancelClick} color="secondary">
            {cancelBtnLlb}
          </Button>
        )}
        <Button onClick={okClick} color="secondary" autoFocus>
          {okBtnLlb}
        </Button>
      </DialogActions>
    </>
  );
};

XpnrConfirmDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  okBtnLlb: PropTypes.string,
  okClick: PropTypes.func,
  cancelClick: PropTypes.func,
  cancelBtnLlb: PropTypes.string,
  showCancelBtn: PropTypes.bool,
};

XpnrConfirmDialog.defaultProps = {
  okClick: () => {},
  cancelClick: () => {},
  showCancelBtn: true,
  title: '',
  okBtnLlb: '예',
  cancelBtnLlb: '아니오',
};
export default XpnrConfirmDialog;
