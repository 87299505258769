import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import attachApi from 'src/api/AttachApi';
import machineApi from 'src/api/MachineApi';
import XpnrUtils from 'src/utils/XpnrUtils';

export const addMachine = createAsyncThunk('mchown/addMachine', async (param, { rejectWithValue }) => {
  try {
    const response = await machineApi.insertMachine(param);
    return response;
  } catch (error) {
    return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

export const updateMachine = createAsyncThunk('mchown/updateMachine', async (param, { rejectWithValue }) => {
  try {
    const response = await machineApi.updateMachine(param);
    return response;
  } catch (error) {
    return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

export const deleteMachine = createAsyncThunk(
  'mchown/deleteMachine',
  async ({ machineId, history }, { rejectWithValue }) => {
    try {
      await machineApi.deleteMachine(machineId);
      history.push('/main');
    } catch (error) {
      XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
    }
  }
);

export const updateMachineImg = createAsyncThunk('mchown/updateMachineImg', async (param, { rejectWithValue }) => {
  try {
    const { attachNo } = await attachApi.upload(param.machineFile);

    const response = await machineApi.updateMachineImg({
      machineId: param.machineId,
      attachNo,
    });

    return response;
  } catch (error) {
    return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

const defaultMachineState = {
  machineId: '',
  productId: '',
  brandId: '',
  modelId: '',
  nickname: '',
  serialNo: '',
  warrantyStartDate: '',
  productionYear: '',
  address: '',
  latitude: 0,
  longitude: 0,
  machineSrc: '',
  machineFile: '',
  attachNo: '',
  selectedMachine: false,
};

const machineSlice = createSlice({
  name: 'mchown/machine',
  initialState: {
    machine: defaultMachineState,
    isFirst: false,
  },
  reducers: {
    setMachine: (state, action) => ({
      ...state,
      machine: action.payload,
    }),
    setIsFirst: (state, action) => ({
      ...state,
      isFirst: action.payload,
    }),
    setDefaultMachine: (state, action) => ({
      ...state,
      machine: defaultMachineState,
    }),
  },
  extraReducers: {
    [addMachine.fulfilled]: (state, action) => ({
      ...state,
      machine: action.payload,
    }),
    [updateMachine.fulfilled]: (state, action) => ({
      ...state,
      machine: action.payload,
    }),
    [deleteMachine.fulfilled]: (state, action) => ({
      ...state,
      machine: defaultMachineState,
    }),
    [updateMachineImg.fulfilled]: (state, action) => ({
      ...state,
      machine: action.payload,
    }),
  },
});

export const { setMachine, setIsFirst, setDefaultMachine } = machineSlice.actions;
export default machineSlice.reducer;
