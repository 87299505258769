import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import codeApi from 'src/api/CodeApi';
import XpnrUtils from 'src/utils/XpnrUtils';

// paramCode : [{ code1: '001', code2: '001' }]
export const getMultiCodeList = createAsyncThunk(
  'code/getMultiCodeList',
  async (paramCode, { getState, rejectWithValue }) => {
    // Rdux저장소에 이미 있는 정보이면 제외하고 서버로 전송
    const codeStore = getState().xpnr.code;

    // 001_001 -> { code1: '001', code2: '001' }
    const existCodes = _.chain(codeStore)
      .keys()
      .map((p) => {
        const codes = p.split('|');
        return { code1: codes[0], code2: codes[1] };
      })
      .value();

    const _paramCode = _.xorWith(paramCode, existCodes, _.isEqual);

    // 서버에서 받을 정보가 없으면...
    if (_.isEmpty(_paramCode)) {
      return {
        type: 'EXIST__CODELIST',
      };
    }

    try {
      const response = await codeApi.getMultiCodeList(paramCode);
      return response;
    } catch (error) {
      return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
    }
  }
);

const codeSlice = createSlice({
  name: 'code',
  initialState: {},
  reducers: {},
  extraReducers: {
    [getMultiCodeList.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export default codeSlice.reducer;
