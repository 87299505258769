import _ from 'lodash';
import { colors, createTheme, responsiveFontSizes } from '@material-ui/core';
import { THEMES } from 'src/config';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

const fuseDark = {
  50: '#e3e6e8',
  100: '#bac0c5',
  200: '#8c969f',
  300: '#5e6c78',
  400: '#3c4d5b',
  500: '#192d3e',
  600: '#162838',
  700: '#122230',
  800: '#0e1c28',
  900: '#08111b',
  A100: '#5b9aff',
  A200: '#287bff',
  A400: '#005ef4',
  A700: '#0054da',
};

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[200],
          },
        },
      },
      MuiAppBar: {
        root: {
          background: '#fff',
        },
      },
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: '#E5E5E5',
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      primary: fuseDark,
      secondary: {
        main: '#004727',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
];

const _createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(_.merge({}, baseOptions, themeOptions, { direction: config.direction }));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

export default _createTheme;
