import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

class MoServiceApi {
  save = (srvcReq) => {
    const formData = new FormData();
    _.mapKeys(srvcReq, (value, key) => {
      if (value == null) {
        return;
      }
      if (key === 'srDateStart' || key === 'srDateEnd' || key === 'srTime') {
        formData.append(key, moment(value).format('YYYY-MM-DD[T]HH:mm:ss'));
      } else {
        formData.append(key, value);
      }
    });

    return new Promise((resolve, reject) => {
      axios
        .post('/api/mchown/service/req', formData, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[MoService Api]: ', err.response);
          reject(new Error(msg || '서비스 등록에 실패하였습니다.'));
        });
    });
  };

  getSrvcReq = (srId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/mchown/service/req/${srId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[MoService Api]: ', err.response);
          reject(new Error(msg || '서비스 조회에 실패하였습니다.'));
        });
    });
  };

  delete = (srId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/mchown/service/req/${srId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[MoService Api]: ', err.response);
          reject(new Error(msg || '서비스 삭제에 실패하였습니다.'));
        });
    });
  };

  getSrvcReqList = () => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mchown/service/my', { useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[MoService Api]: ', err.response);
          reject(new Error(msg || '서비스 리스트 조회에 실패하였습니다.'));
        });
    });
  };

  getSrvcEngineer = (srId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/mchown/service/srveng/${srId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[MoService Api]: ', err.response);
          reject(new Error(msg || '엔지니어 정보 조회에 실패하였습니다.'));
        });
    });
  };

  getRecentSrvc = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mchown/main/recent-service', {
          params,
          useInterceptors: false,
        })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[MoService Api]: ', err.response);
          reject(new Error(msg || '최근 수리 내역 조회에 실패하였습니다.'));
        });
    });
  };
}

const moServiceApi = new MoServiceApi();

export default moServiceApi;
