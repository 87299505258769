export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
};

export const AWS_S3_ROOT_FOLDER = process.env.REACT_APP_AWS_S3_ROOT_FOLDER;
export const NCP_CLIENT_ID = process.env.REACT_APP_NCP_CLIENT_ID;
export const IAMPORT_IMP_ID = process.env.REACT_APP_IAMPORT_IMP_ID;
export const XPNR_APP_ID = 'APP00000000000000001';

export const URI_CONFIG = {
  landingUrl: `${window.location.origin}/`,
  mainUrl: `${window.location.origin}/#/main`,
  loginUrl: process.env.REACT_APP_LOGIN_URL,

  stroe_uri: process.env.REACT_APP_STROE_URI,
  worker_uri: process.env.REACT_APP_RENT_WORKER_URI,
  owner_uri: process.env.REACT_APP_FINDER_MO_URI,
  account_uri: process.env.REACT_APP_ACCOUNT_URI || '',

  use_terms_uri: 'https://rectangular-board-8da.notion.site/4bc5c42749644d97be6ff023c579ab08?pvs=4',
  privacy_terms: 'https://rectangular-board-8da.notion.site/6aa62041c43d4a02adfdf95897a4b13d?pvs=4',
  location_terms: 'https://rectangular-board-8da.notion.site/c869b07f8a324f8f929c6abe1ecf676e?pvs=4',
};

export const AWS_CONFIG = {
  region: process.env.REACT_APP_AWS_REGION,
  cognitoAppClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  cognitoCookieDomain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
};

export const MO_APP_CONFIG = {
  scheme: process.env.REACT_APP_MO_APP_SCHEME,
  packageId: process.env.REACT_APP_MO_APP_PACKAGE,
};

export const AUTH_ROLES = {
  mchown: 'ROLE_MACHINE_OWNER',
};
