import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { Tool as ToolIcon, User as UserIcon } from 'react-feather';
import { Badge, Container } from '@material-ui/core';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { useHistory } from 'react-router-dom';
import { URI_CONFIG } from 'src/config';

const useStyles = makeStyles((theme) => ({
  root: {
    ...(/iPhone|iPad|iPod/i.test(navigator.userAgent)
      ? {
          paddingBottom: 15,
          height: 'auto',
        }
      : {}),
    backgroundColor: theme.palette.background.paper,
    bottom: 0,
    padding: 0,
    width: '100%',
    position: 'absolute',
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .MuiBottomNavigationAction-root.Mui-selected': {
      color: theme.palette.secondary.main,
    },
    '& .MuiBottomNavigationAction-label.Mui-selected': {
      fontSize: '0.75rem',
    },
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -4,
    top: 4,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: 10,
    height: 17,
    backgroundColor: '#546e7a',
  },
}))(Badge);

const navData = [
  {
    id: 'worker',
    name: '렌트',
    icon: (
      <StyledBadge badgeContent="Beta" color="primary" overlap="rectangular">
        <WorkOutlineIcon />
      </StyledBadge>
    ),
  },
  { id: 'store', name: '스토어', icon: <StorefrontIcon /> },
  { id: 'owner', name: '파인더', icon: <ToolIcon /> },
  { id: 'mypage', name: '전체', icon: <UserIcon /> },
];

export default function XpnrBottomNavigation() {
  const classes = useStyles();
  const history = useHistory();

  const onChangeHandler = (event, newValue) => {
    switch (newValue) {
      case 'worker':
        window.location.assign(URI_CONFIG.worker_uri);
        break;
      case 'store':
        window.location.assign(URI_CONFIG.stroe_uri);
        break;
      case 'owner':
        history.push('/main');
        break;
      case 'mypage':
        window.location.assign(URI_CONFIG.account_uri);
        break;
      default:
        break;
    }
  };

  return (
    <BottomNavigation value="owner" onChange={onChangeHandler} showLabels className={classes.root}>
      {navData.map((nav) => (
        <BottomNavigationAction key={nav.id} label={nav.name} icon={nav.icon} value={nav.id} />
      ))}
    </BottomNavigation>
  );
}
