import { makeStyles, AppBar, Typography, Toolbar, IconButton, Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 0,
  },
}));

const PageAppBar = ({ leftButtonIcon, leftButtonAction, title, rightContent, hideLeftBtn }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <AppBar className={classes.appbar} elevation={0}>
      <Container maxWidth="md" className={classes.toolbar}>
        <Toolbar>
          {hideLeftBtn ? (
            <Box p={3} />
          ) : (
            <IconButton aria-label="back" onClick={leftButtonAction || history.goBack}>
              {leftButtonIcon}
            </IconButton>
          )}
          <Typography className={classes.title} variant="h3" color="textPrimary" align="center" noWrap>
            {title}
          </Typography>
          {rightContent}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

PageAppBar.propTypes = {
  leftButtonIcon: PropTypes.node,
  leftButtonAction: PropTypes.func,
  title: PropTypes.string,
  rightContent: PropTypes.node,
  hideLeftBtn: PropTypes.bool,
};

PageAppBar.defaultProps = {
  leftButtonIcon: <ArrowBackIcon />,
  rightContent: <Box p={3} />,
  hideLeftBtn: false,
};

export default PageAppBar;
