/* global kakao */

// 1. React JS 컴포넌트 import
import React, { useEffect, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import { useDispatch, useSelector } from 'react-redux';

// 2. 디자인 컴포넌트 import
import { Box, Container, Dialog, IconButton, makeStyles, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// 3. Xpanner JS 기본 컴포넌트 import
import PageAppBar from 'src/components/PageAppBar';
import { closeKakaoPostcodeDialog, setAddress } from 'src/store/xpnr/kakaoPostcodeSlice';

// 4. 업무 화면 컴포넌트 import
import NaverMapStep from './NaverMapStep';

// 5. 업무 redux 컴포넌트 import

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E5E5E5',
    flexGrow: 1,
  },
  pageContent: {
    padding: '64px 0 0 0',
    width: '100%',
    height: 'calc(100% - 56px)',
  },
  iframeWrap: {
    // position: 'fixed',
    width: '100%',
    height: 'calc(100% - 56px)',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function KakaoPostcodeDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [geocoder, setGeoCoder] = useState(null);
  const [tempMapData, setTempMapData] = useState({});

  // kakao api 로딩
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_API_APPKEY}&autoload=false&libraries=services`;
    document.head.appendChild(script);

    script.onload = () => {
      kakao.maps.load(() => {
        setGeoCoder(new kakao.maps.services.Geocoder());
      });
    };
  }, []);

  const state = useSelector(({ xpnr }) => xpnr.kakaoPostcode.state);

  // 다이얼로그 오픈시.. 주소검색도 초기화
  useEffect(() => {
    if (state.open) setStep(0);
  }, [state]);

  const closeKakaoDialog = () => {
    dispatch(closeKakaoPostcodeDialog());
  };

  const moveToNaverApi = (data) => {
    dispatch(
      setAddress({
        address: data.address,
      })
    );
  };

  const handleDaumPostcodeComplete = (data) => {
    try {
      // 주소를 좌표로 변환
      geocoder.addressSearch(data.address, async (result, status) => {
        // 정상적으로 검색이 완료됐으면
        if (status === kakao.maps.services.Status.OK) {
          setTempMapData({
            address: data.address,
            latitude: result[0].y,
            longitude: result[0].x,
          });
          // 네이버 지도에 표시
          setStep(1);
        } else {
          moveToNaverApi(data);
        }
      });
    } catch (e) {
      moveToNaverApi(data);
    }
  };

  return (
    <Dialog
      aria-labelledby="kakao-postcode-dialog-title"
      fullScreen
      onClose={closeKakaoDialog}
      TransitionComponent={Transition}
      {...state}
    >
      <div className={classes.root}>
        <PageAppBar
          hideLeftBtn
          title="주소 검색"
          rightContent={
            <IconButton aria-label="back" onClick={closeKakaoDialog}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Container className={classes.pageContent} maxWidth="md">
          {step === 0 && <DaumPostcode onComplete={handleDaumPostcodeComplete} height="100%" hideMapBtn hideEngBtn />}
          {step === 1 && <NaverMapStep tempMapData={tempMapData} />}
        </Container>
      </div>
    </Dialog>
  );
}

export default KakaoPostcodeDialog;
