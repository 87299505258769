import { Fragment, Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import MainLayout from 'src/layout/MainLayout';
import MainView from 'src/views//mchown/main/MainView';
import { AuthGuard } from './components/authentication/AuthGuard';
import { OwnerGuard } from './components/authentication/OwnerGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/main/:tabId?',
    component: MainView,
    layout: MainLayout,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: '/mchown/addMachine',
    component: lazy(() => import('src/views/mchown/machine/AddMachineView')),
    layout: MainLayout,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: '/mchown/editMachine/:id',
    component: lazy(() => import('src/views/mchown/machine/EditMachineView')),
    layout: MainLayout,
    guard: OwnerGuard,
  },
  {
    exact: true,
    path: '/mchown/machine/:id',
    component: lazy(() => import('src/views/mchown/machine/MachineInfoView')),
    layout: MainLayout,
    guard: OwnerGuard,
  },
  {
    exact: true,
    path: '/mchown/calendar',
    component: lazy(() => import('src/views/mchown/calendar/CalendarView')),
    layout: MainLayout,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: '/mchown/service/add/:srType?',
    component: lazy(() => import('src/views/mchown/srvc/add/AddServiceView')),
    layout: MainLayout,
    guard: OwnerGuard,
  },
  {
    exact: true,
    path: '/mchown/service/edit/:srId?',
    component: lazy(() => import('src/views/mchown/srvc/add/EditServiceView')),
    layout: MainLayout,
    guard: OwnerGuard,
  },
  {
    exact: true,
    path: '/mchown/service/detail/:srId',
    component: lazy(() => import('src/views/mchown/srvc/my/DetailServiceView')),
    layout: MainLayout,
    guard: OwnerGuard,
  },
  {
    exact: true,
    path: '/mchown/service/feedback/:srId',
    component: lazy(() => import('src/views/mchown/srvc/my/SrvcFeedbackView')),
    layout: MainLayout,
    guard: OwnerGuard,
  },
  {
    exact: true,
    path: '/mchown/service/quotation/:quotId',
    component: lazy(() => import('src/views/mchown/srvc/my/QuotationView')),
    layout: MainLayout,
    guard: OwnerGuard,
  },
  {
    exact: true,
    path: '/myservice/:srId',
    component: lazy(() => import('src/views/mchown/srvc/my/MyServiceView')),
    layout: MainLayout,
    guard: OwnerGuard,
  },
  {
    exact: true,
    path: '/mypage/recent',
    component: lazy(() => import('src/views/mchown/mypage/RecentSrvc')),
    layout: MainLayout,
    guard: OwnerGuard,
  },
  {
    exact: true,
    path: '/bridge',
    component: lazy(() => import('src/views/BridgeView')),
  },
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/main" />,
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
