import axios from 'axios';
import _ from 'lodash';

class MachineApi {
  getListMachineDTO = () => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mchown/machine', { useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[Machine Api]: ', err.response);
          reject(new Error(msg || '장비 목록 조회에 실패하였습니다.'));
        });
    });
  };

  insertMachine = (param) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      _.mapKeys(param, (value, key) => formData.append(key, value));
      axios
        .post('/api/mchown/machine', formData, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[Machine Api]: ', err.response);
          reject(new Error(msg || '장비 등록에 실패하였습니다.'));
        });
    });
  };

  updateMachine = (param) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      _.mapKeys(param, (value, key) => formData.append(key, value));
      axios
        .put('/api/mchown/machine', formData, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[Machine Api]: ', err.response);
          reject(new Error(msg || '장비 수정에 실패하였습니다.'));
        });
    });
  };

  deleteMachine = (machineId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/mchown/machine/${machineId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[Machine Api]: ', err.response);
          reject(new Error(msg || '장비 삭제에 실패하였습니다.'));
        });
    });
  };

  updateMachineImg = (param) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      _.mapKeys(param, (value, key) => formData.append(key, value));

      axios
        .post('/api/mchown/machine/img', formData, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[Machine Api]: ', err.response);
          reject(new Error(msg || '장비 이미지 변경에 실패하였습니다.'));
        });
    });
  };

  getMachineBySerialNo = (serianNo) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/mchown/machine/serial-no/${serianNo}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[Machine Api]: ', err.response);
          reject(new Error(msg || '시리얼번호 중복 조회에 실패하였습니다.'));
        });
    });
  };
}

const machineApi = new MachineApi();

export default machineApi;
