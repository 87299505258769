// eslint-disable-next-line max-classes-per-file
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ko';
import store from 'src/store';
import SnackBarUtils from './SnackBarUtils';

const emptyImgSrc = '/assets/images/main/empty.jpg';

// 시간정보 셋팅
function getListHours() {
  const items = [];
  Array.from({ length: 15 }, (__, i) => i + 8).forEach((i) => {
    items.push({
      label: moment({ hour: i }).format('A h:mm'),
      hour: moment({ hour: i }).format('HH:mm'),
    });
    // items.push(moment({ hour: index, minute: 30 }).format('A h:mm'));
  });
  return items;
}

const hours = getListHours();

class XpnrUtils {
  // Xpnr 전용함수 추가 2020-12-13
  static getAttachURL(attachNo) {
    if (_.isEmpty(attachNo)) {
      return emptyImgSrc;
    }
    return `/api/v2/cmm/attach/down/${attachNo}`;
  }

  static getEmptyImgSrc() {
    return emptyImgSrc;
  }

  static getFirstAttachUrl(atchGrpNo) {
    if (_.isEmpty(atchGrpNo)) {
      return emptyImgSrc;
    }
    return `/api/v2/cmm/atchgrp/down/${atchGrpNo}/first`;
  }

  // 공통코드 관련 유틸
  // checkCode: [ { code1: '001', code2: '001' } ]
  // codeStore: { 001_001: Array() }
  // checkCode의 코드값이 codeStore에 모두 존재할경우 true를 출력
  static isCheckCmmCode(checkCode, codeStore) {
    const load = _.map(checkCode, (p) => {
      const key = `${p.code1}|${p.code2}`;
      return !_.isEmpty(codeStore[key]);
    });
    return _.every(load);
  }

  static cmmCodeToName(cod1, code2, code3) {
    const codeStore = store.getState().xpnr.code;
    const codeList = codeStore[`${cod1}|${code2}`];
    const obj = _.findLast(codeList, (p) => p.code === code3);
    if (obj) return obj.name;
    return 'unknown';
  }

  static getUrlParameter(search, name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);

    const results = regex.exec(search);
    return results === null ? '' : results[1];
  }

  static getHours() {
    return hours;
  }

  static viewKorean(num) {
    const hanA = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구', '십'];

    const danA = ['', '십', '백', '천', '', '십', '백', '천', '', '십', '백', '천', '', '십', '백', '천'];
    let result = '';

    for (let i = 0; i < num.length; i += 1) {
      let str = '';
      const han = hanA[num.charAt(num.length - (i + 1))];
      if (han !== '') str += han + danA[i];
      if (i === 4) str += '만';
      if (i === 8) str += '억';
      if (i === 12) str += '조';
      result = str + result;
    }
    if (num !== 0) {
      result = result.concat('원');
    }

    return result;
  }

  // 숫자를 한글로 변환하는 함수
  static num2han(_num) {
    // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
    let num = `${Math.round(_num)}`;
    num = parseInt(num.replace(/[^0-9]/g, ''), 10);
    num = `${num}`;
    if (num === '0') return '영';

    const number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
    const unit = ['', '만', '억', '조'];
    const smallUnit = ['천', '백', '십', ''];
    const result = []; // 변환된 값을 저장할 배열

    // 단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
    const unitCnt = Math.ceil(num.length / 4);
    // 4자리 값이 되도록 0을 채운다
    num = num.padStart(unitCnt * 4, '0');

    const regexp = /[\w\W]{4}/g; // 4자리 단위로 숫자 분리
    const array = num.match(regexp);

    // 여기로 들어오는 값은 무조건 네자리이다. 1234 -> 일천이백삼십사
    function _makeHan(text) {
      let str = '';
      for (let i = 0; i < text.length; i += 1) {
        const num2 = text[i];
        if (num2 !== '0') {
          str += number[num2] + smallUnit[i];
        }
      }
      return str;
    }

    // 낮은 자릿수에서 높은 자릿수 순으로 값을 만든다(그래야 자릿수 계산이 편하다)
    for (let i = array.length - 1, unitCnt2 = 0; i >= 0; i -= 1, unitCnt2 += 1) {
      const hanValue = _makeHan(array[i]); // 한글로 변환된 숫자
      if (hanValue !== '') {
        // unshift는 항상 배열의 앞에 넣는다.
        result.unshift(hanValue + unit[unitCnt2]);
      } else {
        // 값이 없을땐 해당 단위의 값이 모두 0이란 뜻.
      }
    }

    result.push(' 원');

    return result.join('');
  }

  // generate unique id for each device(in a browser)
  // https://stackoverflow.com/questions/27247806/generate-unique-id-for-each-device
  static generateDeviceUID() {
    const navigatorInfo = window.navigator;
    const screenInfo = window.screen;
    let uid = navigatorInfo.mimeTypes.length;
    uid += navigatorInfo.userAgent.replace(/\D+/g, '');
    uid += navigatorInfo.plugins.length;
    uid += screenInfo.height || '';
    uid += screenInfo.width || '';
    uid += screenInfo.pixelDepth || '';
    // console.log({ navigatorInfo, screenInfo, uid });
    return uid;
  }

  static goBack = (history, url) => () => {
    if (window.history.state == null || window.history.state.idx === 0) {
      if (url === '/mypage') {
        this.goInAppChckNMypage(history);
      } else {
        history.replace(url);
      }
    } else {
      history.goBack();
    }
  };

  // createAsyncThunk 에러 처리 공통으로..
  static catchThunkErrorHandler(err, rejectWithValue) {
    const { message } = err;
    SnackBarUtils.error(message);

    return rejectWithValue(err);
  }
}

export default XpnrUtils;
