import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import srvcRcptApi from 'src/api/SrvcRcptApi';
import XpnrUtils from 'src/utils/XpnrUtils';

export const getListQuotation = createAsyncThunk('mchown/getListQuotation', async (srvcId, { rejectWithValue }) => {
  try {
    const response = await srvcRcptApi.getListQuotation(srvcId);
    return response;
  } catch (error) {
    return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

const quotationsAdapter = createEntityAdapter({
  selectId: (quotation) => quotation.quotId,
});

const quotationsSlice = createSlice({
  name: 'mchown/quotations',
  initialState: quotationsAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getListQuotation.fulfilled]: (state, action) => {
      quotationsAdapter.setAll(state, action.payload);
    },
  },
});

export const {
  selectAll: selectQuotations,
  selectById: selectQuotationById,
  selectTotal: selectQuotationsTotal,
} = quotationsAdapter.getSelectors((state) => state.mchown.quotationList);

export default quotationsSlice.reducer;
