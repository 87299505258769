import { Box, Button, Divider, List, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NaverMap } from 'react-naver-maps';
import { withNavermaps } from 'react-naver-maps/dist/hocs.cjs';
import { useDispatch } from 'react-redux';
import { closeKakaoPostcodeDialog, setAddress } from 'src/store/xpnr/kakaoPostcodeSlice';

import NaverMapUtils from 'src/utils/NaverMapUtils';
import SnackBarUtils from 'src/utils/SnackBarUtils';
import AddressListItem from './AddressListItem';

const initCenterPoint = { lat: 36.4782476, lng: 127.2887994 };
const initAddressItem = { roadAddress: '', jibunAddress: '' };
const useStyles = makeStyles((theme) => ({
  naverMapWrap: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
  },
  naverMap: {
    width: '100%',
    height: 'calc(100% - 160px)',
  },
}));

function NaverMapStep({ navermaps, tempMapData }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [mapRef, setMapRef] = useState(null);
  const [centerPoint, setCenterPoint] = useState(initCenterPoint);

  const [addressItem, setAddressItem] = useState(initAddressItem);
  const [returnPoint, setReturnPoint] = useState(initCenterPoint);

  const searchCoordinateToAddress = (latlng) => {
    navermaps.Service.reverseGeocode(
      {
        coords: latlng,
        orders: [navermaps.Service.OrderType.ADDR, navermaps.Service.OrderType.ROAD_ADDR].join(','),
      },
      (status, response) => {
        if (status === navermaps.Service.Status.ERROR) {
          SnackBarUtils.error('위치검색 실패!');
          return;
        }

        const items = response.v2.results;
        const _addressItem = {};
        for (let i = 0; i < items.length; i += 1) {
          const item = items[i];
          const address = NaverMapUtils.makeAddress(item) || '';
          const addrTypeVal = item.name === 'roadaddr' ? 'roadAddress' : 'jibunAddress';
          _addressItem[addrTypeVal] = address;
        }
        setAddressItem(_addressItem);
        setReturnPoint({ latitude: latlng.y, longitude: latlng.x });
      }
    );
  };

  // 마커표시 혹은 이동
  const settingMapkerNclick = (position) => {
    const markerObj = new navermaps.Marker({
      position,
      map: mapRef.instance,
    });

    mapRef.instance.addListener('click', (e) => {
      console.log(e.coord);

      markerObj.setPosition(e.coord);
      searchCoordinateToAddress(e.coord);
    });
  };

  useEffect(() => {
    if (!mapRef) return;

    let position = null;
    const { latitude, longitude, address } = tempMapData;
    if (latitude === 0 && longitude === 0 && address.length === 0) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          // 위치정보로 지도센터 옮기기
          setCenterPoint({
            lat: pos.coords.latitude, // 위도
            lng: pos.coords.longitude, // 경도
          });

          position = new navermaps.LatLng(pos.coords.latitude, pos.coords.longitude);

          settingMapkerNclick(position);
          searchCoordinateToAddress(position);
        });
      }
    } else {
      position = new navermaps.LatLng(latitude, longitude);
      settingMapkerNclick(position);
      searchCoordinateToAddress(position);

      // 위치정보로 지도센터 옮기기
      setCenterPoint({
        lat: latitude, // 위도
        lng: longitude, // 경도
      });
    }
  }, [mapRef]);

  const closeDialog = () => {
    dispatch(
      setAddress({
        address: addressItem.jibunAddress,
        latitude: returnPoint.latitude,
        longitude: returnPoint.longitude,
      })
    );
    dispatch(closeKakaoPostcodeDialog());
  };

  return (
    <div className={classes.naverMapWrap}>
      <NaverMap
        id="react-naver-maps"
        naverRef={(ref) => setMapRef(ref)}
        className={classes.naverMap}
        center={centerPoint}
        mapTypeControl
        zoom={15}
      />
      <Divider />
      <List component="nav" aria-label="address list" disablePadding>
        <AddressListItem item={addressItem} />
      </List>
      <Box p={1} width={1}>
        <Button fullWidth size="large" variant="contained" color="secondary" onClick={closeDialog}>
          완료
        </Button>
      </Box>
    </div>
  );
}

NaverMapStep.propTypes = {
  navermaps: PropTypes.shape(),
  tempMapData: PropTypes.shape(),
};

export default withNavermaps(NaverMapStep);
