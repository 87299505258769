import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import { useHistory } from 'react-router';

const initialState = {
  code: {},
  isInAppInit: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    default: {
      return { ...state };
    }
  }
};

const XpnrContext = createContext({
  ...initialState,
});

export const XpnrProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <XpnrContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </XpnrContext.Provider>
  );
};

XpnrProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default XpnrContext;
