import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as cognito from 'src/services/cognito-service';
import SnackBarUtils from 'src/utils/SnackBarUtils';
import { removeAuthorizationHeader } from 'src/utils/axios';
import { URI_CONFIG } from 'src/config';

const detectingOS = () => {
  if (navigator.userAgent.indexOf('like Mac') !== -1) return 'iOS';
  if (navigator.userAgent.indexOf('Android') !== -1) return 'Android';
  if (navigator.userAgent.indexOf('Win') !== -1) return 'Windows';
  if (navigator.userAgent.indexOf('Linux') !== -1) return 'Linux';
  if (navigator.userAgent.indexOf('Mac') !== -1) return 'Macintosh';
  return 'Unknown OS';
};

const initialState = {
  isInApp: true,
  isInAppInit: false,
  xpnrAppId: '',
  version: '',
  os: 'android',
  showBottomNavigation: true,
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'CHECK_IS_INAPP':
      return {
        ...state,
        isInApp: action.payload,
      };
    case 'COMPLATE_INAPP_INIT': {
      const { xpnrAppId, version, os } = action.payload;
      return {
        ...state,
        isInAppInit: true,
        xpnrAppId,
        version,
        os,
        showBottomNavigation: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const InAppContext = createContext({
  ...initialState,
  callWebToApp: () => Promise.resolve(),
  goInAppChckNMypage: () => {},
  goInAppChckNNotiPage: () => {},
  goInAppChckLoginPage: () => {},
  signOutofAxiosInterceptor: () => {},
  getHeightXpnrBottomNavi: () => 0,
});

export const InAppProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isMounted, setIsMounted] = useState(false);

  const init = async () => {
    if (!window.flutter_inappwebview) {
      // 플루터앱 인앱브라우저 아닌경우는 여기서 끝남.
      console.log('[InAppService] this browser is not flutter_inappwebview');
      console.log(navigator.userAgent);

      dispatch({ type: 'CHECK_IS_INAPP', payload: false });

      return;
    }
    console.log('[InAppService] this browser is flutter_inappwebview');
    console.log(navigator.userAgent);
    window.pushRouter = (path) => history.push(path);
    window.reloadRouter = () => history.go(0);
    window.forceSignOut = () => {
      delete axios.defaults.headers.common.Authorization;
      cognito.signOut();
    };

    const handlerCallback = async (result) => {
      const { xpnrAppId, version, accessToken, idToken, refreshToken } = result;

      if (accessToken !== '' && idToken !== '' && refreshToken !== '') {
        await cognito.setCurrentSession({ accessToken, idToken, refreshToken });
      } else {
        window.forceSignOut();
      }

      dispatch({
        type: 'COMPLATE_INAPP_INIT',
        payload: {
          xpnrAppId,
          version,
          os: detectingOS(),
        },
      });
    };
    try {
      if (window.flutter_inappwebview.callHandler) {
        const result = await window.flutter_inappwebview.callHandler('sendAppToWebAppInfo');
        await handlerCallback(result);
      }
      window.addEventListener('flutterInAppWebViewPlatformReady', async () => {
        const result = await window.flutter_inappwebview.callHandler('sendAppToWebAppInfo');
        await handlerCallback(result);
      });
    } catch (e) {
      console.error(e);
      SnackBarUtils.error(e.message || '앱 버전 동기화 중에 오류가 발생했습니다.');
    }
  };

  // 앱에 특정처리를 호출해야 할때  ex) openLoginScreen
  const callWebToApp = async (action, data) => {
    console.log('callWebToApp START', action, data);
    window.flutter_inappwebview.callHandler('callWebToApp', action, data).then((result) => {
      console.log('callWebToApp END', result);
    });
  };

  const goInAppChckNMypage = () => {
    if (state.isInApp) {
      callWebToApp('goToProfileView');
    } else {
      window.location.href = `${URI_CONFIG.account_uri}`;
    }
  };

  const goInAppChckNNotiPage = () => {
    if (state.isInApp) {
      callWebToApp('openNotification');
    } else {
      window.location.href = `${URI_CONFIG.account_uri}/notification`;
    }
  };

  const goInAppChckLoginPage = (location, setChecked) => {
    if (state.isInApp) {
      setChecked(false);
    } else {
      const returnTo = `${URI_CONFIG.landingUrl}#${location.pathname}`;
      const searchParams = new URLSearchParams({ redirect_uri: returnTo }).toString();
      const href = `${URI_CONFIG.loginUrl}?${searchParams}`;
      window.location.assign(href);
    }
  };

  const signOutofAxiosInterceptor = () => {
    if (state.isInApp) {
      callWebToApp('openLoginScreen');
    } else {
      cognito.signOut();
      removeAuthorizationHeader();
      window.location.replace(`${URI_CONFIG.loginUrl}?redirect_uri=${window.location.href}`);
    }
  };

  const getHeightXpnrBottomNavi = () => {
    return state.isInApp ? 0 : (/iPhone|iPad|iPod/i.test(navigator.userAgent) ? 15 : 0) + 56;
  };

  useEffect(() => {
    if (isMounted) {
      init();
    }
  }, [isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <InAppContext.Provider
      value={{
        ...state,
        callWebToApp,
        goInAppChckNMypage,
        goInAppChckNNotiPage,
        goInAppChckLoginPage,
        signOutofAxiosInterceptor,
        getHeightXpnrBottomNavi,
      }}
    >
      {children}
    </InAppContext.Provider>
  );
};

InAppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InAppContext;
