import { createSlice } from '@reduxjs/toolkit';

const kakaoPostcodeSlice = createSlice({
  name: 'kakaoPostcodeDialog',
  initialState: {
    state: {
      open: false,
    },
    data: {
      address: '',
      latitude: 0,
      longitude: 0,
    },
  },
  reducers: {
    openKakaoPostcodeDialog: (state, action) => {
      state.state.open = true;
    },
    closeKakaoPostcodeDialog: (state, action) => {
      state.state.open = false;
    },
    setAddress: (state, action) => {
      state.data = action.payload;
    },
    setDefaultData: (state) => {
      state.data = {
        address: '',
        latitude: 0,
        longitude: 0,
      };
    },
  },
});

export const { openKakaoPostcodeDialog, closeKakaoPostcodeDialog, setAddress, setDefaultData } =
  kakaoPostcodeSlice.actions;

export default kakaoPostcodeSlice.reducer;
