import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import machineApi from 'src/api/MachineApi';
import XpnrUtils from 'src/utils/XpnrUtils';

export const getMachines = createAsyncThunk('mchown/getMachines', async (_, { rejectWithValue }) => {
  try {
    const machineList = await machineApi.getListMachineDTO();
    // 날짜를 숫자만 입력하도록!!
    const _machineList = machineList.map((machine) => ({
      ...machine,
      warrantyStartDate: machine.warrantyStartDate ? machine.warrantyStartDate.split('-').join('') : null,
    }));

    return _machineList;
  } catch (err) {
    return XpnrUtils.catchThunkErrorHandler(err, rejectWithValue);
  }
});

const machinesAdapter = createEntityAdapter({
  selectId: (machine) => machine.machineId,
});

const machinesSlice = createSlice({
  name: 'mchown/machineList',
  initialState: machinesAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getMachines.fulfilled]: (state, action) => {
      machinesAdapter.setAll(state, action.payload);
    },
  },
});

export const {
  selectAll: selectMachines,
  selectById: selectMachinesById,
  selectTotal: selectMachinesTotal,
} = machinesAdapter.getSelectors((state) => state.mchown.machineList);

export default machinesSlice.reducer;
