import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import srvcRcptApi from 'src/api/SrvcRcptApi';
import XpnrUtils from 'src/utils/XpnrUtils';
import history from 'src/utils/history';

export const getQuotation = createAsyncThunk('mchown/getQuotation', async (quotId, { rejectWithValue }) => {
  try {
    const response = await srvcRcptApi.getQuotation(quotId);

    return response;
  } catch (error) {
    return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

export const confirmQuotation = createAsyncThunk('mchown/confirmQuotation', async (quotId, { rejectWithValue }) => {
  try {
    srvcRcptApi.confirmQuotation(quotId);

    history.back();
  } catch (error) {
    XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

export const rejectQuotation = createAsyncThunk(
  'mchown/rejectQuotation',
  async ({ quotId, modifyCode, modifyReason }, { rejectWithValue }) => {
    try {
      srvcRcptApi.rejectQuotation({ quotId, modifyCode, modifyReason });

      history.back();
    } catch (error) {
      XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
    }
  }
);

const defaultQuotationState = {
  quotId: '',
  revision: 1,
  srvcId: '',
  moId: '',
  quotStatus: '',
  addTaxRate: '',
  currency: '',
  subTotalAmount: 0,
  totalAmount: 0,
  moRequestDate: new Date(),
  moConfirmDate: new Date(),
  repairLabors: [],
  repairParts: [],
  seShopOrderDTOList: [],
  delYn: false,
  regId: '',
  regDate: new Date(),
  modId: '',
  modDate: new Date(),
};

const quotationSlice = createSlice({
  name: 'mchown/quotation',
  initialState: defaultQuotationState,

  reducers: {
    setQuotation: (state, action) => action.payload,
    setDefaultQuotation: (state, action) => defaultQuotationState,
  },
  extraReducers: {
    [getQuotation.fulfilled]: (state, action) => action.payload,
  },
});

export const { setQuotation, setDefaultQuotation } = quotationSlice.actions;
export default quotationSlice.reducer;
