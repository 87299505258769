import axios from 'axios';
import _ from 'lodash';
import { AWS_S3_ROOT_FOLDER } from 'src/config';

// 그룹첨부 서버단 연결
class AtchGrpService {
  upload = (form) => {
    const { action, atchGrpNo, fileType, imageList, delAtchNos } = form;
    // ImgListUploader의 form 의 정보임..
    return new Promise((resolve, reject) => {
      // form.action에 따라 분기하여 서버호출
      if (action === 'insert') {
        this.insert(imageList, fileType).then((regAtchGrpNo) => resolve({ action, atchGrpNo: regAtchGrpNo }));
      } else if (action === 'update') {
        this.update(atchGrpNo, imageList, delAtchNos, fileType).then((chgAtchGrpNo) =>
          resolve({ action, atchGrpNo: chgAtchGrpNo })
        );
      } else if (action === 'delete') {
        this.delete(atchGrpNo).then(() => resolve({ action }));
      }
    });
  };

  // AttacheGroupDTO 를 던지고 atchGrpNo 를 받음
  insert = (imgList, fileType = 'ATCHF999') => {
    // AttacheGroupDTO 구조로 가공해서 던짐
    const formData = new FormData();
    formData.append('fileType', fileType);
    _.map(imgList, (item) => {
      // ImgListUploader 의 imageList 정보구조 참고
      formData.append('files', item.imgFile);
    });
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v2/cmm/atchgrp/upload', formData, {
          headers: {
            'Content-Type': `multipart/form-data, boundary=${formData._boundary}`,
          },
          params: {
            rootFolder: AWS_S3_ROOT_FOLDER,
          },
        })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          console.error('[AtchGrp Api]: ', err.response.data.msg);
          reject(new Error('파일 업로드를 실패하였습니다.'));
        });
    });
  };

  // AttacheGroupDTO 를 던지고 atchGrpNo 를 받음
  update = (atchGrpNo, imgList, delAtchNos, fileType = 'ATCHF999') => {
    // AttacheGroupDTO 구조로 가공해서 던짐
    const formData = new FormData();
    formData.append('atchGrpNo', atchGrpNo);
    formData.append('fileType', fileType);
    formData.append('delAtchNos', delAtchNos);
    const relAtchNos = [];
    _.map(imgList, (item) => {
      if (item.imgFile) {
        // ImgListUploader 의 imageList 정보구조 참고
        formData.append('files', item.imgFile);
      } else {
        relAtchNos.push(item.attachNo);
      }
    });
    formData.append('relAtchNos', relAtchNos);
    return new Promise((resolve, reject) => {
      axios
        .put('/api/v2/cmm/atchgrp/upload', formData, {
          headers: {
            'Content-Type': `multipart/form-data, boundary=${formData._boundary}`,
          },
          params: {
            rootFolder: AWS_S3_ROOT_FOLDER,
          },
        })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          console.error('[AtchGrp Api]: ', err.response.data.msg);
          reject(new Error('파일 업로드를 실패하였습니다.'));
        });
    });
  };

  delete = (atchGrpNo) => {
    return new Promise((resolve) => {
      axios.delete(`/api/v2/cmm/atchgrp/${atchGrpNo}`).then(() => {
        resolve();
      });
    });
  };

  getList = (atchGrpNo) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/atchgrp/${atchGrpNo}`)
        .then((response) => resolve(response.data.list))
        .catch((err) => {
          console.error('[AtchGrp Api]: ', err.response.data.msg);
          reject(new Error('파일 리스트 조회에 실패하였습니다.'));
        });
    });
  };

  getThumbnail = (atchGrpNo) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/atchgrp/${atchGrpNo}/first`)
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          console.error('[AtchGrp Api]: ', err.response.data.msg);
          reject(new Error('파일 썸네일 조회에 실패하였습니다.'));
        });
    });
  };

  canBeUpload = (uploadForm) => {
    if (_.isEmpty(uploadForm)) return false;

    const { action, imageList, delAtchNos } = uploadForm;

    let result = true;
    if (action === 'insert' && imageList.length === 0) {
      result = false;
    } else if (action === 'update' && imageList.length === 0 && delAtchNos === 0) {
      result = false;
    }
    return result;
  };
}

const atchGrpService = new AtchGrpService();

export default atchGrpService;
