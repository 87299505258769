import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from 'src/hooks/useAuth';
import { useInApp } from 'src/hooks/useInapp';

export const AuthGuard = ({ children }) => {
  const location = useLocation();

  const { isAuthenticated } = useAuth();
  const [checked, setChecked] = useState(false);

  const { goInAppChckLoginPage } = useInApp();

  const check = useCallback(() => {
    if (!isAuthenticated) {
      goInAppChckLoginPage(location, setChecked);
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, location.pathname]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated]
  );

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
