import XpnrUtils from 'src/utils/XpnrUtils';
import _ from 'lodash';
import { getMultiCodeList } from 'src/store/xpnr/codeSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useCmCodeLoadEffect = (complatefunc, deps) => {
  const [loadCmmCode] = deps;

  // 0: 처음 호출 상태
  // 1: 코드 서버에서 요청한 상태
  const [loadingStaus, setloadingStaus] = useState(0);
  const dispatch = useDispatch();
  const code = useSelector(({ xpnr }) => xpnr.code);
  useEffect(() => {
    if (_.isEmpty(code)) return;
    const _isComplate = XpnrUtils.isCheckCmmCode(loadCmmCode, code);
    if (_isComplate) {
      complatefunc();
    }
  }, [code]);

  // 코드 존재여부 체크
  const isComplate = XpnrUtils.isCheckCmmCode(loadCmmCode, code);
  if (isComplate) return;

  if (loadingStaus === 0) {
    setloadingStaus(1);
    dispatch(getMultiCodeList(loadCmmCode));
  }
};

export default useCmCodeLoadEffect;
