import axios from 'axios';
import _ from 'lodash';

class SrvcRcptApi {
  getQuotation = (quotId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/mchown/service/quot/${quotId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[SrvcRcpt Api]: ', err.response);
          reject(new Error(msg || '견적서 조회에 실패하였습니다.'));
        });
    });
  };

  confirmQuotation = (quotId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/mchown/service/confirm-quot/${quotId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[SrvcRcpt Api]: ', err.response);
          reject(new Error(msg || '견적서 승인에 실패하였습니다.'));
        });
    });
  };

  rejectQuotation = ({ quotId, modifyCode, modifyReason }) => {
    const formData = new FormData();
    formData.append('quotId', quotId);
    formData.append('modifyCode', modifyCode);
    formData.append('modifyReason', modifyReason);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/mchown/service/reject-quot`, formData, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[SrvcRcpt Api]: ', err.response);
          reject(new Error(msg || '견적서 수정요청에 실패하였습니다.'));
        });
    });
  };

  getListQuotation = (srvcId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/mchown/service/quot/list/${srvcId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[SrvcRcpt Api]: ', err.response);
          reject(new Error(msg || '견적서 리스트 조회에 실패하였습니다.'));
        });
    });
  };

  getListPayment = (quotId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/mchown/service/pay/${quotId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[SrvcRcpt Api]: ', err.response);
          reject(new Error(msg || '결제 리스트 조회에 실패하였습니다.'));
        });
    });
  };
}

const srvcRcptApi = new SrvcRcptApi();

export default srvcRcptApi;
