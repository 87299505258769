import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import moServiceApi from 'src/api/MoServiceApi';
import XpnrUtils from 'src/utils/XpnrUtils';

export const getSrvcReqList = createAsyncThunk('mchown/getSrvcReqList', async (_, { rejectWithValue }) => {
  try {
    const response = await moServiceApi.getSrvcReqList();
    return response;
  } catch (err) {
    return XpnrUtils.catchThunkErrorHandler(err, rejectWithValue);
  }
});

const srvcReqListAdapter = createEntityAdapter({
  selectId: (srvc) => srvc.srId,
});

const srvcReqListSlice = createSlice({
  name: 'mchown/srvcReqList',
  initialState: srvcReqListAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getSrvcReqList.fulfilled]: (state, action) => {
      srvcReqListAdapter.setAll(state, action.payload);
    },
  },
});

export const {
  selectAll: selectSrvcReqList,
  selectById: selectSrvcReqListById,
  selectTotal: selectSrvcReqListTotal,
} = srvcReqListAdapter.getSelectors((state) => state.mchown.srvcReqList);

export default srvcReqListSlice.reducer;
