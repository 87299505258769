import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from 'src/store/xpnr/dialogSlice';

function XpnrDialog(props) {
  const dispatch = useDispatch();
  const state = useSelector(({ xpnr }) => xpnr.dialog.state);
  const options = useSelector(({ xpnr }) => xpnr.dialog.options);

  return (
    <Dialog open={state} onClose={(ev) => dispatch(closeDialog())} aria-labelledby="fuse-dialog-title" {...options} />
  );
}

export default XpnrDialog;
