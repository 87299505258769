import { makeStyles, AppBar, Typography, Toolbar, IconButton, Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  banner: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));

const PageTop = ({ leftButtonIcon, leftButtonAction, title, rightContent, style }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={clsx(classes.banner, style)}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <IconButton aria-label="back" onClick={leftButtonAction || history.goBack}>
          {leftButtonIcon}
        </IconButton>
        {title && (
          <Typography variant="h3" color="textPrimary">
            {title}
          </Typography>
        )}
        {rightContent}
      </Box>
    </Container>
  );
};

PageTop.propTypes = {
  leftButtonIcon: PropTypes.node,
  leftButtonAction: PropTypes.func,
  title: PropTypes.string,
  rightContent: PropTypes.node,
  style: PropTypes.string,
};

PageTop.defaultProps = {
  leftButtonIcon: <ArrowBackIcon />,
  rightContent: <Box p={3} />,
};

export default PageTop;
