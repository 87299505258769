import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useAuth } from 'src/hooks/useAuth';
import { useInApp } from 'src/hooks/useInapp';
import { closeDialog, openDialog } from 'src/store/xpnr/dialogSlice';
import XpnrConfirmDialog from '../XpnrConfirmDialog';

export const OwnerGuard = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { goInAppChckLoginPage } = useInApp();

  const { isAuthenticated, hasRoleOwner } = useAuth();
  const [checked, setChecked] = useState(false);

  const showConfirmDialog = () => {
    dispatch(
      openDialog({
        onClose: () => {},
        children: (
          <XpnrConfirmDialog
            title="장비 등록 필요"
            message={
              <>
                <Typography>현재 서비스요청 및 수정을 하시려면 장비를 등록하여야 합니다.</Typography>
                <Typography>장비를 등록하시겠습니까?</Typography>
              </>
            }
            okClick={() => {
              history.push('/mchown/addMachine');
              dispatch(closeDialog());
            }}
            cancelClick={() => {
              history.goBack();
              dispatch(closeDialog());
            }}
          />
        ),
      })
    );
  };

  const check = useCallback(() => {
    if (isAuthenticated && hasRoleOwner()) {
      setChecked(true);
    } else if (isAuthenticated) {
      showConfirmDialog();
    } else {
      goInAppChckLoginPage(location, setChecked);
    }
  }, [isAuthenticated, location.pathname]);

  useEffect(() => {
    check();
  }, [isAuthenticated]);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};

OwnerGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
