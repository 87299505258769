import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage,
} from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';
import { AWS_CONFIG } from 'src/config';

const cookieStorage = new CookieStorage({
  domain: AWS_CONFIG.cognitoCookieDomain,
  secure: AWS_CONFIG.cognitoCookieDomain !== 'localhost',
  sameSite: AWS_CONFIG.cognitoCookieDomain !== 'localhost' ? 'none' : 'lax',
});

const userPool = new CognitoUserPool({
  UserPoolId: AWS_CONFIG.cognitoUserPoolId,
  ClientId: AWS_CONFIG.cognitoAppClientId,
  Storage: cookieStorage,
});

const createCognitoUserSession = (tokens) => {
  if (!tokens || !tokens.accessToken || !tokens.idToken || !tokens.refreshToken) return undefined;

  return new CognitoUserSession({
    AccessToken: new CognitoAccessToken({ AccessToken: tokens.accessToken }),
    IdToken: new CognitoIdToken({ IdToken: tokens.idToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refreshToken }),
  });
};

export const setCurrentSession = async (tokens) =>
  new Promise((resolve, reject) => {
    const decodedAccesstoken = jwtDecode(tokens.accessToken);

    const cognitoUser = new CognitoUser({
      Username: decodedAccesstoken.username,
      Pool: userPool,
      Storage: cookieStorage,
    });

    const session = createCognitoUserSession(tokens);
    if (session && session.isValid()) {
      cognitoUser.setSignInUserSession(session);
      resolve();
    }
    reject();
  });

export const signOut = async () =>
  // new Promise<void>((resolve, reject) => {
  new Promise((resolve, reject) => {
    try {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          // TODO err 처리
          if (session?.isValid()) {
            cognitoUser.signOut();
            resolve();
          }
        });
      }
    } catch (e) {
      console.error(e);
      reject(new Error('로그아웃에 실패했습니다.'));
    }
  });

export const getCurrentTokens = async () =>
  new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      // session: CognitoUserSession
      cognitoUser.getSession((err, session) => {
        // TODO err 처리
        if (err) {
          cognitoUser.signOut();
          reject(err);
        }
        if (session?.isValid()) {
          resolve({
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
          });
        }
      });
    } else {
      resolve(null);
    }
  });

export const refreshTokens = async () =>
  new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          cognitoUser.signOut();
          reject(err);
        }
        if (session?.isValid()) {
          const refreshToken = session.getRefreshToken();
          cognitoUser.refreshSession(refreshToken, (rErr, rSession) => {
            if (rErr) {
              cognitoUser.signOut();
              reject(err);
            } else {
              resolve({
                accessToken: rSession.getAccessToken().getJwtToken(),
                idToken: rSession.getIdToken().getJwtToken(),
                refreshToken: rSession.getRefreshToken().getToken(),
              });
            }
          });
        }
      });
    } else {
      resolve(null);
    }
  });

// Promise<boolean>
export const checkTokens = async () =>
  new Promise((resolve) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        // TODO err 처리
        if (err) {
          cognitoUser.signOut();
          resolve(false);
        }
        if (session?.isValid()) {
          cognitoUser.setSignInUserSession(session);
          cognitoUser.getUserAttributes((rErr) => {
            if (rErr) {
              cognitoUser.signOut();
              resolve(false);
            } else {
              resolve(true);
            }
          });
        }
      });
    } else {
      resolve(false);
    }
  });
