import axios from 'axios';
import _ from 'lodash';
import { XPNR_APP_ID } from 'src/config';

const useInterceptors = false;

class BizCallApi {
  getVn = (accountId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/bizcall/${accountId}`, {
          params: {
            xpnrAppId: XPNR_APP_ID,
          },
          useInterceptors,
        })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[BizCallApi]: ', err.response);
          reject(new Error(msg || '가상번호 조회에 실패하였습니다.'));
        });
    });
  };
}

const bizCallApi = new BizCallApi();

export default bizCallApi;
