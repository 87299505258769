import { combineReducers } from '@reduxjs/toolkit';
import xpnr from './xpnr';

const createReducer = (asyncReducers) =>
  combineReducers({
    xpnr,
    ...asyncReducers,
  });

export default createReducer;
