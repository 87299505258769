// 1. React JS 컴포넌트 import
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

// 2. 디자인 컴포넌트 import
import {
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DehazeIcon from '@material-ui/icons/Dehaze';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

// 3. Xpanner JS 기본 컴넌트 import
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import useCmCodeLoadEffect from 'src/hooks/useCmCodeLoadEffect';
import withReducer from 'src/store/withReducer';

// 4. 업무 화면 컴포넌트 import
import PageTop from 'src/components/PageTop';
import { URI_CONFIG } from 'src/config';
import { useInApp } from 'src/hooks/useInapp';
import MyMachineList from './MyMachineList';
import MySrvList from './MySrvList';
import ReqSrvBtnDrawer from './ReqSrvBtnDrawer';
import TabPanel from './TabPanel';

// 5. 업무 redux 컴포넌트 import
import reducer from '../store';
import { getMachines, selectMachinesTotal } from '../store/machineListSlice';
import { getSrvcReqList, selectSrvcReqListTotal } from '../store/srvcReqListSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E5E5E5',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  selected: {
    backgroundColor: theme.palette.background.paper,
  },
  cardContent: {
    padding: '8px 8px 0 8px',
    display: 'flex',
    flexDirection: 'column',
  },
  srvReqBtn: {
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    borderRadius: theme.spacing(1),
  },
  srvReqTitle2: {
    fontWeight: 700,
  },
  mySrvTabs: {
    minHeight: 'unset',
  },
  mySrvTab: {
    fontWeight: 700,
    fontSize: 16,
    padding: '4px 12px',
    minHeight: 'unset',
    border: `2px solid ${theme.palette.secondary.main}`,
    borderBottom: 0,
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    marginRight: theme.spacing(0.1),
  },
  mySrvContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px 0px 0px 0px',
  },
  footerBox: {
    padding: '16px',
  },
  footerSize: {
    fontSize: 12,
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const loadCmmCode = [
  { code1: 'MO_CD', code2: 'MO001' },
  { code1: 'MO_CD', code2: 'MO002' },
  { code1: 'MO_CD', code2: 'MO003' },
];

function MainView() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const { tabId = 0 } = useParams();
  const _tabId = Number(tabId);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [cntNoti, setCntNoti] = useState(0);

  const { goInAppChckNNotiPage, goInAppChckNMypage } = useInApp();

  // 장비총갯수 가져오기
  const machinesTotal = useSelector(selectMachinesTotal);
  // 서비스총합 가져오기
  const srTotal = useSelector(selectSrvcReqListTotal);

  // 코드가져오기
  useCmCodeLoadEffect(async () => {
    // 장비목록 가져오기
    dispatch(getMachines());
    // 서비스목록 가져오기
    dispatch(getSrvcReqList());

    setLoading(false);
  }, [loadCmmCode]);

  const handleChange = (event, newValue) => {
    history.replace(`/main/${newValue}`);
  };

  const toggleDrawer = (open) => (event) => {
    setOpenDrawer(open);
  };

  const goNotification = () => goInAppChckNNotiPage();

  const goCalendarView = () => {
    history.push('/mchown/calendar');
  };

  const goMypage = () => goInAppChckNMypage();

  const goUseTerms = () => window.location.assign(URI_CONFIG.use_terms_uri);
  const goPrivacyTerms = () => window.location.assign(URI_CONFIG.privacy_terms);
  const goLocationTerms = () => window.location.assign(URI_CONFIG.location_terms);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Page className={classes.root} title="[장비주] 메인화면">
        <PageTop
          leftButtonAction={goMypage}
          leftButtonIcon={<DehazeIcon />}
          rightContent={
            <Box>
              <IconButton aria-label="back" onClick={goNotification}>
                <Badge overlap="rectangular" color="error" badgeContent={cntNoti}>
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
              <IconButton aria-label="back" onClick={goCalendarView}>
                <CalendarTodayIcon />
              </IconButton>
            </Box>
          }
        />
        <Container maxWidth="sm">
          <div className={classes.cardContent}>
            <Button className={classes.srvReqBtn} variant="contained" color="secondary" onClick={toggleDrawer(true)}>
              <Box width={1} display="flex" alignItems="center" justifyContent="space-between">
                <div>
                  <Typography variant="h6" align="left">
                    장비에 문제가 생겼나요?
                  </Typography>
                  <Typography variant="h3" align="left" className={classes.srvReqTitle2}>
                    수리 서비스 요청하기
                  </Typography>
                </div>
                <ArrowForwardIosIcon />
              </Box>
            </Button>
            <ReqSrvBtnDrawer open={openDrawer} openDrawer={toggleDrawer(true)} closeDrawer={toggleDrawer(false)} />
            <Box mt={2}>
              <Tabs
                classes={{
                  root: classes.mySrvTabs,
                }}
                TabIndicatorProps={{
                  style: {
                    display: 'none',
                  },
                }}
                textColor="secondary"
                value={_tabId}
                onChange={handleChange}
              >
                <Tab
                  label={`나의 서비스 현황 ${srTotal}`}
                  classes={{
                    selected: classes.selected,
                    root: classes.mySrvTab,
                  }}
                  {...a11yProps(0)}
                />
                <Tab
                  label={`장비목록 ${machinesTotal}`}
                  classes={{
                    selected: classes.selected,
                    root: classes.mySrvTab,
                  }}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </div>
        </Container>
        <Container className={classes.mySrvContent} width={1} maxWidth="md">
          <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={_tabId}>
            <TabPanel value={_tabId} index={0} dir={theme.direction}>
              <MySrvList />
            </TabPanel>
            <TabPanel value={_tabId} index={1} dir={theme.direction}>
              <MyMachineList />
            </TabPanel>
          </SwipeableViews>
        </Container>
        <Container maxWidth="md" className={classes.footerBox}>
          <Toolbar>
            <Typography display="inline" color="textSecondary" className={classes.footerSize}>
              주식회사 스패너 (Xpanner Inc.) <br />
              대표자 : 이명한, 신흥주, 박주한 <br />
              사업자등록번호 : 452-86-01771 <br />
              직업정보제공사업면허 신고번호 : J1200020230027 <br />
              주소 : 서울특별시 성동구 성수일로 77, 1603호, 1604호(성수동1가, 서울숲아이티밸리)
              <br />
              전화번호 : 02-6956-1778 <br />
              이메일 : cs@xpanner.com <br />
              <Button size="small" className={classes.footerSize} onClick={goUseTerms}>
                사용자 이용약관
              </Button>
              |
              <Button size="small" className={classes.footerSize} onClick={goPrivacyTerms}>
                개인정보 취급방침
              </Button>
              |
              <Button size="small" className={classes.footerSize} onClick={goLocationTerms}>
                위치정보서비스 약관
              </Button>
              <br />
              <br />
            </Typography>
          </Toolbar>
        </Container>
      </Page>
    </>
  );
}

export default withReducer('mchown', reducer)(MainView);
