import { combineReducers } from '@reduxjs/toolkit';

import machineList from './machineListSlice';
import machine from './machineSlice';
import myService from './myServiceSlice';
import quotation from './quotationSlice';
import quotationList from './quotationListSlice';
import payment from './paymentSlice';
import srvcReqList from './srvcReqListSlice';
import srvcReq from './srvcReqSlice';
import calendar from './calendarSlice';
import recentSrvc from './recentSrvcSlice';

const reducer = combineReducers({
  machine,
  machineList,
  srvcReq,
  srvcReqList,
  myService,
  quotation,
  quotationList,
  payment,
  calendar,
  recentSrvc,
});

export default reducer;
