import { Box, Chip, ListItem, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  addressItemText: {
    '& .MuiChip-root': {
      width: 62,
      height: 26,
    },
    '& .primary, .secondary': {
      padding: '4px 0',
      fontSize: '0.875rem',
    },
    '& .label': {
      padding: 4,
    },
  },
}));

function AddressListItem({ item, ...rest }) {
  const classes = useStyles();

  return (
    <ListItem button divider {...rest}>
      <div className={classes.addressItemText}>
        <Box className="secondary" display="flex">
          <Chip label="주소" color="primary" />
          <span className="label">{item.jibunAddress}</span>
        </Box>
      </div>
    </ListItem>
  );
}

AddressListItem.propTypes = {
  item: PropTypes.shape(),
};

AddressListItem.defaultProps = {
  item: {
    roadAddress: '',
    jibunAddress: '',
  },
};

export default AddressListItem;
