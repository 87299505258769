import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import srvcRcptApi from 'src/api/SrvcRcptApi';
import XpnrUtils from 'src/utils/XpnrUtils';

export const getPaymentInfo = createAsyncThunk('mchown/getPaymentInfo', async (quotId, { rejectWithValue }) => {
  try {
    const response = await srvcRcptApi.getListPayment(quotId);

    return response[response.length - 1];
  } catch (error) {
    return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
  }
});

const defaultPaymentState = {
  payId: '',
  quotId: '',
  moId: '',
  payStatus: '',
  payRequestDate: new Date(),
  name: '',
  payMethod: '',
  pg: '',
  amount: 0,
  currency: '',
  buyerEmail: '',
  buyerName: '',
  buyerTel: '',
  buyerAddress: '',
  buyerPostcode: '',
  payDate: new Date(),
  errorMsg: '',
  impUid: '',
  impResult: '',
  quotation: {},
  delYn: false,
  regId: '',
  regDate: new Date(),
  modId: '',
  modDate: new Date(),
};

const paymentSlice = createSlice({
  name: 'mchown/payment',
  initialState: defaultPaymentState,

  reducers: {
    setPayment: (state, action) => action.payload,
    setDefaultPayment: (state, action) => defaultPaymentState,
  },
  extraReducers: {
    [getPaymentInfo.fulfilled]: (state, action) => action.payload,
  },
});

export const { setPayment, setDefaultPayment } = paymentSlice.actions;
export default paymentSlice.reducer;
