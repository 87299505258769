import axios from 'axios';
import { AWS_S3_ROOT_FOLDER } from 'src/config';

class AttachApi {
  upload = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
      axios
        .post('/api/v2/cmm/attach/upload', formData, {
          headers: {
            'Content-Type': `multipart/form-data, boundary=${formData._boundary}`,
          },
          params: {
            rootFolder: AWS_S3_ROOT_FOLDER,
          },
        })
        .then((response) => resolve(response.data.data))
        .catch((err) => {
          const { msg } = err.response.data;
          console.error('[Attach Api]: ', err.response);
          reject(new Error(msg || '파일 업로드를 실패하였습니다.'));
        });
    });
  };
}

const attachApi = new AttachApi();

export default attachApi;
