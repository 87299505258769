// 1. React JS 컴포넌트 import
import moment from 'moment';
import 'moment/locale/ko';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// 2. 디자인 컴포넌트 import
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

// 3. Xpanner JS 기본 컴포넌트 import
import XpnrUtils from 'src/utils/XpnrUtils';

// 4. 업무 화면 컴포넌트 import
// 5. 업무 redux 컴포넌트 import
import { selectMachines } from '../store/machineListSlice';
import { selectSrvcReqList } from '../store/srvcReqListSlice';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0.5),
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: 0,
    borderRadius: 18,
    '& .MuiSelect-outlined': {
      borderRadius: 18,
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 32px 12.5px 14px',
    },
  },
  clearBtn: {
    borderRadius: 18,
    padding: '7px 14px',
    fontSize: '1rem',
    minWidth: '75px',
  },
  listWrap: {
    height: 'calc(100% - 80px)',
  },
  emptyImg: {
    paddingBottom: theme.spacing(1),
  },
  avator: {
    width: 70,
    height: 70,
  },
  srvTitle1: {
    fontWeight: 700,
  },
  today: {
    backgroundColor: '#B6CAFE',
    color: 'white',
    borderRadius: 8,
    fontSize: 12,
    padding: '0 8px',
    alignSelf: 'center',
    marginLeft: theme.spacing(0.5),
  },
  blueText: {
    color: 'blue',
  },
}));

const MySrvList = () => {
  const history = useHistory();
  const classes = useStyles();
  const [status, setStatus] = React.useState('');
  const [machine, setMachine] = React.useState('');
  const [selectedSrList, setSelectedSrList] = React.useState([]);
  const [selectedSrTotal, setSelectedSrTotal] = React.useState(0);

  // 장비목록 가져오기
  const machines = useSelector(selectMachines);

  // 서비스전체 가져오기
  const srList = useSelector(selectSrvcReqList);

  // 선택되어진 서비스 목록/총합
  useEffect(() => {
    let _srList = [...srList];
    if (status) {
      _srList = _srList.filter((sr) => sr.srStatus === status);
    }
    if (machine) {
      _srList = _srList.filter((sr) => sr.machineId === machine);
    }
    setSelectedSrTotal(_srList.length);
    setSelectedSrList(_srList);
  }, [status, machine, srList]);

  // MO003 코드 리스트
  const MO_CD_MO003 = useSelector(({ xpnr }) => xpnr.code[`MO_CD|MO003`]);

  const clearConditon = () => {
    setStatus('');
    setMachine('');
  };

  // 서비스 상세보기
  const goMyService = (srId) => {
    history.push(`/myservice/${srId}`);
  };

  return (
    <>
      <Box py={1} display="flex" alignItems="center">
        {!(status === '' && machine === '') && (
          <Button className={classes.clearBtn} variant="outlined" color="secondary" onClick={clearConditon}>
            초기화
          </Button>
        )}
        <FormControl className={classes.formControl} variant="outlined" color="secondary">
          <Select
            value={status}
            onChange={(event) => setStatus(event.target.value)}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'status select' }}
          >
            <MenuItem value="" disabled>
              상태별
            </MenuItem>
            {MO_CD_MO003.filter(
              // XPAN-228 select메뉴 조정
              (item) => item.code !== 'MO003999' && item.code !== 'MO003008' && item.code !== 'MO003007'
            ).map((item) => (
              <MenuItem key={item.code} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} variant="outlined" color="secondary">
          <Select
            value={machine}
            onChange={(event) => setMachine(event.target.value)}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'machine select' }}
          >
            <MenuItem value="" disabled>
              장비별
            </MenuItem>
            {machines.map((item) => (
              <MenuItem key={item.machineId} value={item.machineId}>
                {item.nickname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {selectedSrTotal === 0 && (
        <Grid
          className={classes.listWrap}
          width={1}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box py={8}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <img className={classes.emptyImg} alt="empty list" src="/assets/images/main/empty_list.png" />
              <Typography variant="body2" color="textSecondary">
                아직 신청하신 서비스가 없습니다.
              </Typography>
            </Grid>
          </Box>
        </Grid>
      )}

      {selectedSrTotal > 0 && (
        <List className={classes.listWrap} component="nav" aria-label="machine list">
          {selectedSrList.map((item) => (
            <ListItem key={item.srId} button divider disableGutters onClick={() => goMyService(item.srId)}>
              <ListItemAvatar>
                <Avatar className={classes.avator} variant="rounded">
                  <img
                    className={classes.avator}
                    alt="images"
                    src={XpnrUtils.getFirstAttachUrl(item.attachGroupId)}
                    onError={(e) => {
                      e.target.src = XpnrUtils.getEmptyImgSrc();
                    }}
                  />
                </Avatar>
              </ListItemAvatar>
              <Box ml={1}>
                <Box display="flex">
                  <Typography className={classes.srvTitle1} variant="body1">
                    {XpnrUtils.cmmCodeToName('MO_CD', 'MO003', item.srStatus)}{' '}
                    {item.srStatus === 'MO003003' && moment(item.visitStartDate).format('YYYY.MM.DD A h시')}{' '}
                    {item.warrantyCheck === 'D' && <span className={classes.blueText}>(일반수리로변경)</span>}
                  </Typography>

                  {item.srStatus === 'MO003003' &&
                    moment(item.visitStartDate).format('YYYY.MM.DD') === moment(new Date()).format('YYYY.MM.DD') && (
                      <span className={classes.today}>오늘</span>
                    )}
                </Box>

                <Typography variant="body1">
                  {item.fullModelNm} ({item.productionYear}) {item.machineNickname}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {XpnrUtils.cmmCodeToName('MO_CD', 'MO001', item.failurePart)}|
                  {XpnrUtils.cmmCodeToName('MO_CD', 'MO002', item.failureType)}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default MySrvList;
