import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import code from './codeSlice';
import kakaoPostcode from './kakaoPostcodeSlice';

const xpnrReducers = combineReducers({
  dialog,
  code,
  kakaoPostcode,
});

export default xpnrReducers;
