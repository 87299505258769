import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import 'moment/locale/ko';
import bizCallApi from 'src/api/BizcallApi';
import moServiceApi from 'src/api/MoServiceApi';
import XpnrUtils from 'src/utils/XpnrUtils';

export const getSrvcEngineerBySrId = createAsyncThunk(
  'mchown/getSrvcEngineerBySrId',
  async (srId, { rejectWithValue }) => {
    try {
      const response = await moServiceApi.getSrvcEngineer(srId);
      if (response) {
        const vn = await bizCallApi.getVn(response.seId);
        return { ...response, vn };
      }

      return { ...response };
    } catch (error) {
      return XpnrUtils.catchThunkErrorHandler(error, rejectWithValue);
    }
  }
);

export const defaultServiceEngineer = {
  srvcId: '',
  srId: '',
  seId: '',
  usrNm: '',
  phone: '',
  attachNo: '',
  serviceStartYear: '',
  address: '',
};

const myServiceSlice = createSlice({
  name: 'mchown/myService',
  initialState: {
    serviceEngineer: defaultServiceEngineer,
  },
  reducers: {},
  extraReducers: {
    [getSrvcEngineerBySrId.fulfilled]: (state, action) => {
      state.serviceEngineer = action.payload;
    },
  },
});

export default myServiceSlice.reducer;
