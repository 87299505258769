import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import 'moment/locale/ko';
import mchownMainApi from 'src/api/MchownMainApi';

export const getCalendar = createAsyncThunk('mchown/getCalendar', async (thisMonth) => {
  const _thisMonth = moment(thisMonth).format('YYYY-MM-DD HH:mm:ss');
  const response = await mchownMainApi.calendar(_thisMonth);
  return response;
});

const calendarSlice = createSlice({
  name: 'mchown/calendar',
  initialState: [],
  reducers: {},
  extraReducers: {
    [getCalendar.fulfilled]: (state, action) => action.payload,
  },
});

export default calendarSlice.reducer;
