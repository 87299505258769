// 1. React JS 컴포넌트 import
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// 2. 디자인 컴포넌트 import
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// 3. Xpanner JS 기본 컴포넌트 import
import XpnrUtils from 'src/utils/XpnrUtils';

// 4. 업무 화면 컴포넌트 import
// 5. 업무 redux 컴포넌트 import
import { selectMachines } from '../store/machineListSlice';

const useStyles = makeStyles((theme) => ({
  newMchBtn: {
    borderRadius: 18,
    padding: '7px 14px',
    fontSize: '1rem',
    minWidth: '75px',
    fontWeight: 700,
  },
  listWrap: {
    height: 'calc(100% - 80px)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  emptyImg: {
    paddingBottom: theme.spacing(1),
  },
  avator: {
    width: 50,
    height: 50,
  },
  chip: {
    margin: '0 4px',
    fontSize: '0.7rem',
    height: '21px',
  },
}));

const MyMachineList = () => {
  const classes = useStyles();
  const history = useHistory();

  // 장비목록 가져오기
  const machines = useSelector(selectMachines);

  const addMachine = () => {
    history.push('/mchown/addMachine');
  };

  return (
    <>
      <Box py={1} display="flex" alignItems="center">
        <Button className={classes.newMchBtn} color="secondary" onClick={addMachine}>
          <AddCircleOutlineIcon />{' '}
          <Box component="span" ml={0.5} mr={1}>
            장비 등록하기
          </Box>
        </Button>
      </Box>
      {machines.length === 0 ? (
        <Grid
          className={classes.listWrap}
          width={1}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <img className={classes.emptyImg} alt="empty list" src="/assets/images/main/empty_list.png" />
            <Typography variant="body2" color="textSecondary">
              등록한 장비가 없습니다.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <List className={classes.listWrap} component="nav" aria-label="main mailbox folders">
          {machines.map((item) => (
            <ListItem
              key={item.machineId}
              button
              divider
              disableGutters
              onClick={() => {
                history.push(`/mchown/machine/${item.machineId}`);
              }}
            >
              <ListItemAvatar>
                <Avatar className={classes.avator} variant="rounded" src={XpnrUtils.getAttachURL(item.attachNo)}>
                  <img className={classes.avator} src={XpnrUtils.getEmptyImgSrc()} alt="machineEmptyImage" />
                </Avatar>
              </ListItemAvatar>
              <Box ml={1} clone>
                <ListItemText primary={item.fullModelNm} secondary={item.nickname} />
              </Box>
              {item.selectedMachine && (
                <Chip className={classes.chip} color="secondary" size="small" label="대표장비" />
              )}
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default MyMachineList;
